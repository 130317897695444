/*
 * @Author: your name
 * @Date: 2021-12-22 10:45:01
 * @LastEditTime: 2022-04-01 13:42:52
 * @LastEditors: OBKoro1
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEd
 * @FilePath: \chatgpt-web-client\src\service\UserService.js
 */
import ApiService from './ApiService'
class UserService extends ApiService {
    GetUserInfo() {
        return super.Get('/api/user/info/get');
    }
    GetUserProfile() {
        return super.Get('/api/user/profile');
    }
    UpdateUserInfo(data) {
        return super.Post('/api/user/info/update', data);
    }
    SendSMSCode(phone) {
        return super.Get(`/api/auth/sendcode?phone=${phone}`);
    }
    GetMessageCount(userID) {
        return super.Get(`/api/user/${userID}/GetCount/`);
    }
    GenerateQRCode(clientID) {
        return super.Get(`/api/auth/qrcode/${clientID}`);
    }
    CheckQRAuth(clientID) {
        return super.Get(`/api/auth/checkQrAuth/${clientID}`);
    }
    Feedback(data) {
        return super.Post("/api/user/Feedback", data);
    }
    UploadPhoto(data) {
        return super.Upload("/api/upload/UserPhoto", data);
    }
}
export default new UserService()