<!--
 * @Author: your name
 * @Date: 2021-11-22 10:30:24
 * @LastEditTime: 2022-11-12 14:02:49
 * @LastEditors: WangZhengJie wangzj@geovis.com.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \chatgpt-web-client\src\pages\feedback.vue
-->
<template>
  <div id="feedback">
    <headerbar leftvisible="true" title="意见反馈" />
    <div class="box" style="padding-bottom: 100px">
      <div class="box-body" style="padding-top: 0">
        <div class="feedback">
         <textarea rows="10" v-model="content" style="font-size:16px;" placeholder="请输入您宝贵的意见" class="input-area"></textarea>
         <div class="opt">
           <button class="btn btn-primary" @click="feedback">提交</button>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userService from '../services/UserService'
export default {
  name: "feedback",
  components: {},
  computed: {},
  data() {
    return {
      content:'',
    };
  },
  methods: {
    feedback(){
      userService.Feedback({
        Content:this.content
      }).then(s=>{
        if(s.Success){
          this.message.info('您的反馈已经提交成功');
          history.back();
        }
      })
    }
  },
  created: function () {},
  mounted: function () {},
};
</script>
<style lang="css" scoped>
@import url("/style/css/common.css");
.feedback {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.feedback .input-area{
  flex:1;
  border: 1px solid #eee;
}
.feedback .opt{
        justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 20px 0;
}
</style>
